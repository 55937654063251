<template>
  <s-dialog
    v-if="idleFlag"
    v-model:visible="dialogShow"
    class="coupon-result"
    :show-close="true"
    @close-from-icon="handleIconMask"
    @close-from-mask="handleIconMask"
  >
    <template #title>
      <span>{{ dialogTitle }}</span>
    </template>
    <ul class="coupon-result__content">
      <coupon-item
        v-for="(item, index) in couponList"
        :key="`${item.couponId}-${index}`"
        :item="item"
        :language="language"
        expose-id="1-11-1-111"
      />
    </ul>
    <template #footer>
      <s-button
        width="8.186667rem"
        :type="['primary', 'H88PX']"
        @click="handleClose('BOTTOM_BUTTON')"
      >
        {{ language?.SHEIN_KEY_PWA_15146 }}
      </s-button>
      <p 
        class="coupon-result__tip"
        @click="handleLink"
      >
        {{ language?.SHEIN_KEY_PWA_18220 }}
      </p>
    </template>
  </s-dialog>
  <div v-else></div>
</template>

<script>
import {
  COUPON_MONITOR,
  GB_BI_COMMON_COUPON_PACKAGE,
} from '../analysis/index.js'
import { setLocalStorage } from '@shein/common-function'
import couponItem from 'public/src/pages/common/common-coupon/CouponItemBff.vue'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
const { langPath } = gbCommonInfo
import { COUPON_BA_ACT_ID } from './constants/coupon'
const sceneCode = {
  'Home': 2
}

export default {
  name: 'CouponResult',
  components: {
    couponItem,
    SButton,
    SDialog
  },
  emits: ['onCouponClose'],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    scene: {
      type: String,
      default: 'Home'
    },
    language: {
      type: Object,
      default: () => {}
    },
    // 劵包数据
    couponList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      dialogShow: false,
      idleFlag: false
    }
  },
  computed: {
    dialogTitle() {
      const { couponList, language } = this
      // 绑劵成功 ｜ 已绑劵
      return couponList?.length ? language?.SHEIN_KEY_PWA_16339 : language?.SHEIN_KEY_PWA_18219
    },
    // 是否是已领取弹窗
    isReceived() {
      return !this.couponList?.length 
    }
  },
  watch: {
    show: {
      handler(val) {
        if (val && this.couponList?.length) {
          // 劵弹窗曝光埋点
          this.analysis_operator('exposePop')
          // 自动领劵埋点
          this.analysis_operator('autoCouponGet')
          // 绑定接口调用后设置10分钟缓存，避免跟全局劵包弹窗冲突
          setLocalStorage({ key: 'hideCoupon', value: '1', expire: 1000 * 60 * 10 })
          setLocalStorage({ key: 'couponPackageLastTime', value: new Date().getTime() })
          setLocalStorage({ key: 'hideCoupon_type', value: '10min' })
          // 劵包链路埋点
          COUPON_MONITOR.normaMonitor({
            code: '605',
            show_judge: 1,
            remark: '触发劵包弹窗弹出'
          })
        }

        this.dialogShow = val

        if (this.isReceived && this.dialogShow) {
          // 已领取弹窗曝光
          this.analysis_operator('exposePopupCouponFailed')
        }
      },
      immediate: true
    },
  },
  mounted() {
    requestIdleCallback(() => {
      this.idleFlag = true
    })
  },
  methods: {
    handleIconMask() { // 不直接使用close回调，避免handleLink时触发两次埋点上报
      this.handleClose('CLOSE')
    },
    handleClose(type = '') {
      this.dialogShow = false
      this.$emit('onCouponClose')

      if(this.isReceived) {
        // 已领取弹窗关闭埋点
        this.analysis_operator('clickPopupGotedClose', type)
      } else {
        // 券包弹窗关闭埋点
        this.analysis_operator('closePop')
      }

    },
    handleLink() {
      this.handleClose('CHECK_COUPON')
      window.location.href = `${langPath}/user/coupon`
    },
    analysis_operator(typeId = '', clickType = '') {
      // 预留自定义样式配置
      const _config_ = {}
      const login = isLoginFn()
      const type = sceneCode[this.scene] || 0

      switch (typeId) {
        case 'closePop':
          GB_BI_COMMON_COUPON_PACKAGE.click_pop_coupon_close({
            coupons: this.couponList,
            _config_,
            type,
          })
          break
        case 'exposePop':
          GB_BI_COMMON_COUPON_PACKAGE.expose_pop_coupon({
            login_type: login ? 'logged' : 'logout',
            coupons: this.couponList,
            _config_,
            type
          })
          break
        case 'popCouponOk':
          GB_BI_COMMON_COUPON_PACKAGE.click_pop_coupon_ok({
            coupons: this.couponList,
            _config_,
            type
          })
          break
        case 'popCouponReceive':
          GB_BI_COMMON_COUPON_PACKAGE.click_pop_coupon_receive({
            coupons: this.couponList,
            _config_,
            type
          })
          break
        case 'autoCouponGet':
          GB_BI_COMMON_COUPON_PACKAGE.click_auto_coupon_get({
            // success | failure  弹窗默认为success
            status: 'success',
            login_type: login ? 'logged' : 'logout',
            coupons: this.couponList,
            type
          })
          break
        case 'exposePopupCouponFailed':
          GB_BI_COMMON_COUPON_PACKAGE.expose_popup_coupon_collection_failed({ type })
          break
        case 'clickPopupGotedClose':
          GB_BI_COMMON_COUPON_PACKAGE.click_coupongotten_popup({
            actId: COUPON_BA_ACT_ID[clickType]
          })
          break
      }
    },
  },
}
</script>

<style lang="less" scoped>
.coupon-result {
  :deep(.sui-dialog__title) {
    padding-bottom: 0.2667rem;
  }
  :deep(.sui-dialog__body) {
    padding: 0 0.2667rem;
    // 解决ios 13.5显示不出来的bug
    overflow: visible; 
  }
  :deep(.sui-dialog__normal) {
    width: 8.72rem;
    max-width: 100%;
  }
  :deep(.sui-dialog__footer) {
    padding: .32rem .2667rem;
  }
  &__content {
    overflow: auto;
    max-height: 5.733333rem;
  }
  &__tip {
    padding-top: .32rem;
    color: #003569;
    .font-dpr(28px);
  }
}
</style>
